import React from "react"

import moment from "moment"

import schedule from "../../content/meta/schedule.json"

export function flatten(nodes) {
	return React.Children.toArray(nodes).reduce((flatChildren, child) => {
		if (child.type === React.Fragment) {
			return flatChildren.concat(flatten(child.props.children))
		}
		flatChildren.push(child)
		return flatChildren
	}, [])
}

export function classNames() {
	return {
		className: Array.prototype.slice
			.call(arguments)
			.filter(cls => cls !== undefined && cls !== null && cls !== "")
			.reduce((acc, cur) => `${acc} ${cur}`, ""),
	}
}

export function trackText(track) {
	switch (track) {
		case "keynote":
			return "keynote"
		// talks
		case "java":
			return "java / jvm"
		case "js":
			return "js / web"
		case "devops":
			return "operations"
		// interactives
		case "cloud":
			return "containers / cloud"
		case "security":
			return "security"
		case "quality":
			return "quality"
		default:
			throw new Error("Unknown track: " + track)
	}
}

export function trackType(track) {
	switch (track) {
		case "keynote":
		case "java":
		case "js":
		case "devops":
			return "talk"
		case "cloud":
		case "security":
		case "quality":
			return "interactive"
		default:
			throw new Error("Unknown track: " + track)
	}
}

export function eventFormatText(format) {
	switch (format) {
		case "talks":
			return "talk"
		case "ignite-talks":
			return "ignite talk"
		case "qa":
			return "q&a"
		case "panels":
			return "panel"
		case "trainings":
			return "training"
		default:
			throw new Error("Unknown event format: " + format)
	}
}

export function timeSlotUtcText(start, end) {
	var slot = moment.utc(start, "HH:mm").format("HH:mm")
	if (end) slot += " to " + moment.utc(end, "HH:mm").format("HH:mm")
	slot += " UTC"
	return slot
}

export function timeSlotText(start, end) {
	var slot = timeSlotUtcText(start, end)
	slot +=
		" (" +
		moment
			.utc(start, "HH:mm")
			.utcOffset(120)
			.format("HH:mm")
	if (end)
		slot +=
			" to " +
			moment
				.utc(end, "HH:mm")
				.utcOffset(120)
				.format("HH:mm")
	slot += " CEST)"
	return slot
}

export function findBlock(slug) {
	const block = schedule.blocks.find(
		block =>
			(block.talk && block.talk === slug) ||
			(block.talks && block.talks.includes(slug)) ||
			(block.interactive && block.interactive === slug) ||
			(block.interactives && block.interactives.includes(slug))
	)
	if (block) return block

	return schedule.blocks
			.filter(block => block.events)
			.flatMap(block => block.events)
			.filter(event => event.interactive)
			.find(event => event.interactive === slug)
}
